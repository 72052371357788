import { useMemoizedContentGetter } from 'utils/contentful'
import { Typography } from 'components/Contentful/Typography'
import Section from 'components/Section'
import styles from './ProblemStatement.module.scss'
import { AchieveTheme } from '@achieve/ascend'
import classNames from 'classnames'

function ProblemStatement({ content }) {
  const { title, subtitle } = useMemoizedContentGetter(content, ['title', 'subtitle'])
  return (
    <Section
      className={styles['section']}
      contain={true}
      backgroundColor={AchieveTheme?.ascend?.colors?.blue[500]}
    >
      {title && (
        <Typography
          data-testid="problem-statement-title-text"
          content={title.textContent}
          fontWeight="bold"
          className={classNames(styles['title'], 'headingLg lg:displayLg')}
        />
      )}
      {subtitle && (
        <Typography
          data-testid="problem-statement-subtitle-text"
          content={subtitle.textContent}
          className={classNames(
            styles['subtitle'],
            'bodyLg lg:displaySm font-light lg:font-medium'
          )}
        />
      )}
    </Section>
  )
}
export { ProblemStatement }
export default ProblemStatement
